<!-- Background video -->
<div class="video-background-holder">
    <!-- Background black area is created using the overlay -->
    <div class="video-background-overlay"></div>
    <!-- Background content is where the video is held to make it fit the page but not display as the background across other screens. -->
    <div class="video-background-content container h-100">
        <!-- Text will be centered and aligned in a presentable way -->
        <div class="d-flex h-100 text-center align-items-center">
            <div class="w-100 text-white">
                <h1 class="display-2">Welcome!</h1>
                <hr style="border: 1px solid white">
                <h2>I have decided to move away from Squarespace and code my own site.</h2>
                <h4>While I work on this, all my Python lessons are on YouTube!</h4>
                <hr style="border: 1px solid white">
                <!-- Button to link to the next page. -->
                <button type="button" class="btn btn-outline-light" style="margin-top: 5px" onclick="location.href='https://www.youtube.com/channel/UCXJ4kLyywUxU9AWDCnPy_AA'">YouTube Channel</button>
            </div>
        </div>
    </div>
</div>
