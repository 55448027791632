import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {LoginComponent} from "./login/login.component";
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['']);

// Use canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } , to secure pages
const routes: Routes = [
  { path: '',  component: DashboardComponent},
  { path: '**', component: DashboardComponent}
/*  { path: 'login', component: LoginComponent, data: { authGuardPipe: redirectLoggedInToDashboard }},
  { path: '**', component: PageNotFoundComponent},*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
