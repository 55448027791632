<!--<div *ngIf="authService.isLoggedIn()">-->
  <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/">Option Panel</a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="navbar-nav mr-auto">
        <a class="nav-link" routerLink="/">Home <span class="sr-only">(current)</span></a>
      </div>
<!--      <div class="navbar-nav">-->
<!--        <button class="btn btn-sm btn-outline-danger" (click)="authService.logout()">Log Out</button>-->
<!--      </div>-->

    </div>
  </nav>
<!--</div>-->

