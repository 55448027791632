import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import { AngularFireAuth} from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { Observable} from "rxjs";

@Injectable()
export class AuthService {
  public username: string;
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router) {
    this.user = _firebaseAuth.authState;

    this.user.subscribe(
        (user) => {
          if (user) {
            this.username = user.email;
            this.userDetails = user;
          } else {
            this.userDetails = null;
          }
        }
    );
  }

  signInRegular(email, password) {
    const credential = firebase.auth.EmailAuthProvider.credential( email, password );
    return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }


  isLoggedIn() {
    if (this.userDetails == null ) {
      return false;
    } else {
      return true;
    }
  }

  logout() {
    this._firebaseAuth.signOut()
        .then((res) => this.router.navigate(['/login']));
  }
}
