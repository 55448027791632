// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyAu__bm6t_yHm-HJTNztT7QgAgUESGOAaY",
    authDomain: "deltatrading-27ac4.firebaseapp.com",
    databaseURL: "https://deltatrading-27ac4.firebaseio.com",
    projectId: "deltatrading-27ac4",
    storageBucket: "deltatrading-27ac4.appspot.com",
    messagingSenderId: "179432344016",
    appId: "1:179432344016:web:d1e886d7c525d9b691d1d5",
    measurementId: "G-4GPBMZ2MD6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
